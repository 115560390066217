import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Row, Label, Input, Button, Table, Spinner } from 'reactstrap';
import UserService from '../../services/UserService';
import stockService from '../../services/StockService';
import { handleErrorMessage, changeFormatOfDateString } from "../../services/CommonService";
import CustomerDashboardQuickLinks from '../../components/CustomerDashboard/CustomerDashboardQuickLinks';

export default class StockLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            searchText: "",
            finelines: [],
            isSearching: false,
            isLoading: false,
            selectedFineline: null,
            stockInfo: null
        };
        this.userService = new UserService();
        this.formatValue = this.formatValue.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStockLevel = this.getStockLevel.bind(this);
        this.searchFinelines = this.searchFinelines.bind(this);
        this.renderStockInfo = this.renderStockInfo.bind(this);
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        this.setState({ user });
    }

    handleChange(value, key) {
        if (key === "searchText") {
            this.setState({ searchText: value });
        }
    }

    formatValue(value) {
        const isValidNumber = /^-?\d*(\.\d+)?$/.test(value);
        return isValidNumber ? Number(value) : value;
    }

    handleClick(selectedFineline) {
        this.setState({ isLoading: true });
        stockService.getStockInfo(selectedFineline.stockItemCd).then(response => {
            this.setState({ isLoading: false, stockInfo: response.data, selectedFineline, searchText: selectedFineline.stockItemCd });
        }).catch(error => {
            this.setState({ isLoading: false, stockInfo: null, selectedFineline: null });
            toast.error(handleErrorMessage(error));
            console.log(error.data);
        });
    }

    searchFinelines() {
        let { searchText, selectedFineline } = this.state;
        if (searchText?.trim() === selectedFineline?.stockItemCd?.trim()) {
            return;
        }
        this.setState({ isSearching: true });
        stockService.searchFinelines(searchText.trim()).then(response => {
            this.setState({ isSearching: false, finelines: response.data });
            const size = response.data?.length;
            if (size === 1) {
                this.handleClick(response.data[0]);
            } else if (size > 1) {
                this.setState({ selectedFineline: null, stockInfo: null });
            } else {
                this.setState({ selectedFineline: searchText, stockInfo: null });
            }
        }).catch(error => {
            this.setState({ isSearching: false, finelines: [], selectedFineline: null, stockInfo: null });
            toast.error(handleErrorMessage(error));
            console.log(error.data);
        });
    }

    getStockLevel(stockLevelIndicator) {
        let className = "fa fa-circle fa-lg text-";
        let title = "Available";
        switch (stockLevelIndicator) {
            case 1:
                className += "danger";
                title = "Out of Stock";
                break;
            case 2:
                className += "warning";
                title = "Low Stock";
                break;
            default:
                className += "success";
                break;
        }
        return <i className={className} title={title} aria-hidden="true"></i>;
    }

    renderStockInfo() {
        const { stockInfo } = this.state;
        if (stockInfo) {
            return <Table striped hover size="sm" responsive bordered className="mb-0">
                <thead>
                    <tr className="text-center">
                        <th>Fineline</th>
                        <th>Description</th>
                        <th>Qty Available</th>
                        <th>Stock Level</th>
                        <th>Next Shipment ETA at Supplier Warehouse</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td>{stockInfo.stockItemCd}</td>
                        <td>{stockInfo.stockItemDescription}</td>
                        <td>{this.formatValue(stockInfo.free)}</td>
                        <td>{this.getStockLevel(stockInfo.stockLevelIndicator)}</td>
                        <td>{stockInfo.stockETA ? changeFormatOfDateString(stockInfo.stockETA, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY") : "N/A"}</td>
                    </tr>
                </tbody>
            </Table>;
        } else {
            return <h4>No Data Found!</h4>;
        }
    }

    render() {
        let { user, searchText, finelines, selectedFineline, isSearching, isLoading } = this.state;
        return (
            <div>
                <div className="text-center">
                    {user && user.menu && user.menu.role === 'ExternalUser' ?
                        <CustomerDashboardQuickLinks size={"sm"} /> : null}
                    <hr />
                </div>
                <div className="ml-3">
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Label className="mb-0"><h4>Fineline</h4></Label>
                            <div className="d-flex justify-content-between">
                                <Input type="search" autoComplete="off" placeholder="Search..." name="searchText" value={searchText} onChange={(e) => this.handleChange(e.target.value, e.target.name)} onKeyDown={(e) => { if (e.key === "Enter" && !isSearching && searchText?.trim() !== "") this.searchFinelines(); }} aria-label="Search for fineline or description" />
                                <Button color="primary" className="ml-2" onClick={() => this.searchFinelines()} disabled={isSearching || searchText?.trim() === ""} aria-label="Search for fineline details">Search</Button>
                            </div>
                        </Col>
                        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                            <div className="text-info">
                                <p className="m-0">Enter complete fineline code, if known, else enter any part(s) of fineline or description (e.g. '6690434', '669', 'lam floor', 'floor spotted gum', etc).</p>
                                <p>Top 20 matches will be returned. Search can be refined, if required.</p>
                            </div>
                        </Col>
                    </Row>
                    {
                        selectedFineline ?
                            this.renderStockInfo()
                            :
                            (isSearching || isLoading) ? <h4>Loading Data <Spinner color="primary" /></h4> :
                                (finelines && finelines.length > 1) ?
                                    <Table striped hover size="sm" responsive bordered className="mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Fineline & Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finelines.map((fineline, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center"><Button color="primary" size="sm" onClick={() => this.handleClick(fineline)}>Select</Button></td>
                                                        <td>{fineline.stockItemCd + " - " + fineline.stockItemDescription}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    : null
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }
}