import React, {Component} from 'react';
import {Card, Col, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row} from 'reactstrap';
import {NavLink as RouterNavLink} from "react-router-dom";
import UserService from '../../services/UserService';
import CustomerService from '../../services/CustomerService';
import {toast} from 'react-toastify';
import queryString from 'query-string';
import {handleErrorMessage} from "../../services/CommonService";


export default class CustomerDashboardQuickLinks extends Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            accountId: "",
            company: "",
            role: "",
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.handleDebtorAccountDetails =  this.handleDebtorAccountDetails.bind(this);
    }


    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentDidMount() {
        let accountId = this.userService.getLoggedInUserAccountID();
        this.userService.getUser().then(user => {
            let role = user.menu.role;
            this.setState({role, accountId}, () => {
                this.handleDebtorAccountDetails(accountId);
            });
        })
    }

    handleDebtorAccountDetails(accountId) {
        if (accountId) {
            this.customerService.getCompanyName(accountId).then(response => {
                if (response.data) {
                    this.setState({company: response.data});
                }
            }).catch(error => {
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        }
    }

    render() {
            return (
                <Navbar light expand="md" className={"rounded p-0"}>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav navbar>

                            <NavItem className={"col-auto mx-auto pl-0"}>
                                <RouterNavLink
                                    exact
                                    style={{textDecoration: "none"}}
                                    to={"/customer/order"}>
                                    <Card className={"ds-9 hoverableItem"} style={{minWidth: 150, maxWidth: 200}}>
                                        <div className={"p-2 text-center"}>
                                            <div>
                                                <i className="fa fa-lg fa-file-text-o" aria-hidden="true"/>
                                            </div>
                                            <div>
                                                <h6 className="mt-1" aria-hidden="true">Orders</h6>
                                            </div>
                                        </div>
                                    </Card>
                                </RouterNavLink>
                            </NavItem>
                            <NavItem className={"col-auto mx-auto pl-0"}>
                                <RouterNavLink
                                    exact
                                    style={{textDecoration: "none"}}
                                    to={"/sales/order/create/product-builder/v1"}>
                                    <Card className={"ds-9 hoverableItem"} style={{minWidth: 150, maxWidth: 200}}>
                                        <div className={"p-2 text-center"}>
                                            <div>
                                                <i className="fa fa-lg fa-cart-plus" aria-hidden="true"/>
                                            </div>
                                            <div>
                                                <h6 className="mt-1" aria-hidden="true">New CTS Order</h6>
                                            </div>
                                        </div>
                                    </Card>
                                </RouterNavLink>
                            </NavItem>
                            <NavItem className="col-auto mx-auto pl-0">
                                <RouterNavLink
                                    exact
                                    style={{ textDecoration: "none" }}
                                    to="/stockLookup">
                                    <Card className="ds-9 hoverableItem" style={{ minWidth: 150, maxWidth: 200 }}>
                                        <div className="p-2 text-center">
                                            <div>
                                                <i className="fa fa-lg fa-search" aria-hidden="true" />
                                            </div>
                                            <div>
                                                <h6 className="mt-1" aria-hidden="true">Stock Lookup</h6>
                                            </div>
                                        </div>
                                    </Card>
                                </RouterNavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <NavbarBrand tag={"span"} className={"mb-0 h2"}>{this.state.company}</NavbarBrand>
                </Navbar>
            )
    }
}